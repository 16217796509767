<template>
    <div>
        {{ $t('dashboard/welcome-welcome') }}
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {
        }
    }, 
    computed: {
    },
    created() {
        this.LogOut()
        this.$router.push({ name: 'Login' })

    },
    methods: {
        ...mapActions({
            LogOut: 'LogOut'
        })
    }
}
</script>